import React from 'react';
import Bio from './Bio';


function About() {

    return (
        <section className="about">
            <Bio />
        </section>
    );
}

export default About;